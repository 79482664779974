export const QAList = [
  {
    img: "",
    cnt: "1、What is a kidney transplant?" ,
    user: false,
    evaluate: false,
    gather: false,
  },
  {
    img: "",
    cnt: "Kidney transplantation is the transplantation of a healthy kidney from a donor (living or deceased) into a patient suffering from end-stage renal disease (e.g. uremia) in order to restore its kidney function. In our country, there are living relative donor kidney transplants and citizen organ donor kidney transplants after death." ,
    user: true,
    evaluate: false,
    gather: false,
  },
  {
    img: "",
    cnt: "2、What are the indications for kidney transplantation?" ,
    user: false,
    evaluate: false,
    gather: false,
  },
  {
    img: "",
    cnt: "Indications are end-stage renal disease due to various causes (including glomerulonephritis, diabetic nephropathy, hypertensive nephropathy, etc.), etc." ,
    user: true,
    evaluate: false,
    gather: false,
  },
  {
    img: "",
    cnt: "3、How to assess suitability for kidney transplantation?" ,
    user: false,
    evaluate: false,
    gather: false,
  },
  {
    img: "",
    cnt: "Doctors will assess the patient's overall health and kidney function through a thorough physical examination, laboratory tests (such as blood and urine tests), and imaging tests." ,
    user: true,
    evaluate: false,
    gather: false,
  },
  {
    img: "",
    cnt: "4、What do I need to do before a kidney transplant?" ,
    user: false,
    evaluate: false,
    gather: false,
  },
  {
    img: "",
    cnt: "A series of assessments (including history taking, physical examination, laboratory tests imaging tests, etc.) are required before kidney transplantation. At the same time, patients need to know information about the risks of surgery, postoperative precautions, and a series of preparations before undergoing kidney transplantation to ensure a smooth operation and postoperative recovery." ,
    user: true,
    evaluate: false,
    gather: false,
  },
  {
    img: "",
    cnt: "5、What do I need to be aware of after a kidney transplant?" ,
    user: false,
    evaluate: false,
    gather: false,
  },
  {
    img: "",
    cnt: "After kidney transplantation, patients need to take immunosuppressants on time and in accordance with medical advice, monitor vital signs (blood pressure, heart rate, 24h urine output, etc.), and follow up regularly to monitor liver and kidney function, electrolytes, blood drug concentration and other indicators. In addition, it is very important to maintain good living habits, good personal hygiene and protection, reasonable diet and moderate exercise." ,
    user: true,
    evaluate: false,
    gather: false,
  },
  {
    img: "",
    cnt: "6、 What is the success rate of kidney transplantation?" ,
    user: false,
    evaluate: false,
    gather: false,
  },
  {
    img: "",
    cnt: "The success rate of kidney transplantation is usually measured in terms of the survival rate of the patient and the transplanted kidney. The success rate of kidney transplantation in China is improving. Some reports indicate that the 1-year and 3-year kidney survival rates after kidney transplantation in China are 97.9 per cent and 92.65 per cent, respectively, reaching a more advanced international level. In addition, a real-world study based on the Chinese Scientific Registry for Kidney Transplantation (CSRKT) showed that the graft survival rate of living donor kidney (LD) kidney transplant recipients at 5 years after surgery was 93%, and the patient survival rate was 97.5%. However, the specifics vary from person to person and are influenced by a variety of factors (including the degree of donor-recipient match, the patient's overall health, surgical technique, and postoperative care and management)." ,
    user: true,
    evaluate: false,
    gather: false,
  },
];